
import {defineComponent, ref} from "vue";
import {Actions, Api} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import {data as roleData, rules as roleRules, definition} from "@/model/role_employee";
import store from "@/store";
import {castData} from "@/core/data/data_prepare";


export default defineComponent({
  name: "AddEmployeeRoleForm",
  components: {},
  data
  () {
    return {
      factories: {},
      factory_roles: {},
      formData: Object.assign(roleData, {
        factory: "",
        factory_role: ""
      }),
      loading: false,
      rules: ref(roleRules),
      isEmployeeLoad: false,
      employee: {},
      employeeId: this.$route.params.id_emp,

    }
  }
  ,
  mounted: function () {
    this.getCurrentEmployee();
    this.getFactories();
  },
  methods: {
    getCurrentEmployee() {
      ApiService.get(Api.GET_EMPLOYEE, {employee_id: this.employeeId}).then(response => {
        this.employee = response.data
        this.formData.employee_id = response.data.employee_id
      })
      this.isEmployeeLoad = true;
    },
    getFactories() {
      let enum_data: [{}];
      enum_data = [{}];
      ApiService.get(Api.ALL_FACTORIES).then(({data}) => {
        enum_data.splice(0, 1);
        data.forEach((row) => {
          let item = {
            id: row.id,
            label: row.factory_number + ' - ' + row.company_name,
          };
          enum_data.push(item);
        });
        this.factories = enum_data;

      }).catch(({response}) => {
        console.error(response.data.errors);
        enum_data = [{}];
      });
    }
    ,
    getRoles() {
      if (this.formData.factory !== "") {
        let params = {
          factory_id: this.formData.factory
        }
        ApiService.get(Api.GET_ROLE_IN_FACTORY, params).then(({data}) => {
          console.log(data);
          this.factory_roles = data;
        }).catch(({response}) => {
          console.error(response.data.errors);
        });
      }
    },
    getWorkEndDate() {
      if (this.formData.work_start_date !== "") {
        let start = new Date(this.formData.work_start_date);
        start.setDate(start.getDate() + 364);
        this.formData.work_end_plan_date = start.toString();
      }
    },
    submit() {
      let formRef
      if (this.$refs.formRef) {
        formRef = ref(this.$refs.formRef);
      } else return;
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.formData.employee_id = this.employeeId.toString();
          let data = castData(this.formData, definition);
          console.log(data);
          this.loading = false;
          store
            .dispatch(Actions.ADD_EMPLOYEE_ROLE, data)
            .then(() => {
              router.push({
                name: "employeeInfo",
                params: {id_emp: this.employeeId}
              });
            });
        }
      });
    }
  }

});
